<template>
  <v-card class="mt-5">
    <v-toolbar
      flat
      dense
      dark
      color="secondary"
    >
      <v-spacer />
      <v-toolbar-title v-text="$t('multiproject.mustChangeEndDate.title')" />
      <v-spacer />
    </v-toolbar>
    <v-card-text>
      <v-alert
        text
        prominent
        color="error"
        icon="mdi-alert"
      >
        <h3
          class="headline"
          v-text="$t('multiproject.mustChangeEndDate.message')"
        />
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MustChangeEndDateContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
}
</script>
